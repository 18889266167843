export default function(data) {

  return {
    track(text) {
      let eventData = {
        event: 'register_event',
        'event_date': data.date,
        'event_language': data.language,
        'event_format': data.format,
        'event_title': data.title,
        'event_region': data.region,
        'event_onDemand': data.onDemand
      };

      console.log(`Tracking event - Event registration link`, eventData);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(eventData);
    }
  };

}
