export default function(hide = false) {
  return {
    isHeaderFixed: false,
    menuOpen: false,
    mobileMenuOpen: false,
    height: null,
    mainHeader: null,
    mainMenu: null,
    mainHeaderShown: true,
    navLogo: 'cxTrends',
    navLogoFocus: false,

    init() {
      this.isHeaderHidden = hide;
      this.mainHeader = this.$refs.mainHeader;
      this.mainMenu = this.$refs.mainMenu;

      if(!this.mainHeader || !this.mainMenu) return;

      this.height = this.mainHeader.clientHeight;

      this.$nextTick(() => this.switchOnScroll());

      window.addEventListener('hide-header', () => { this.isHeaderHidden = true; });
      window.addEventListener('reveal-header', () => { this.isHeaderHidden = false; });
    },

    switchOnScroll() {
      if( window.scrollY >= 32 ) {
        this.isHeaderFixed = true;
        this.navLogo = this.navLogoFocus ? 'cxTrends' : 'logo';
      } else {
        this.isHeaderFixed = false;
        this.navLogo = this.navLogoFocus ? 'logo' : 'cxTrends';
      }

      // if( window.scrollY < this.height*4 ) {
      //   this.menuOpen = false;
      //   this.mainHeaderShown = true;
      // } else if( !this.menuOpen && window.scrollY >= this.height*4 ) {
      //   this.mainHeaderShown = false;
      // }
    },

    openMenu() {
      this.menuOpen = true;
      this.mainHeaderShown = true;
    },

    closeMenu() {
      this.mainHeaderShown = false;
      setTimeout(() => {
        this.menuOpen = false;
      }, 300);
    },

    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      this.$dispatch('mobile-menu-open', { open: this.mobileMenuOpen });

      this.navLogo = ( window.scrollY >= 32 && !this.mobileMenuOpen ) ? 'cxTrends' : 'logo';
    }
  };
}
