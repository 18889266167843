export default function() {

  return {
    track(text) {
      console.log(`Tracking event - ${text}`);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'cta_button',
        'cta_button_name': text
      });
    }
  };

}
