import qs from 'qs';

export function salesUrlShare(baseUrl = '') {
  function removeEmpty(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v != '')
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
  }

  let formDataOrig = '';

  return {
    formData: {
      site : '',
      redirect : '',
      dataCapture: '',
      region: '',
      size: '',
      industry: '',
      utm_campaign: '',
      downloadReport: ''
    },
    required: ['industry', 'region', 'size'],
    get valid() {
      return this.required.filter(r => this.formData[r].length).length === this.required.length;
    },
    get url() {
      let url = new URL(baseUrl);
      let reduced = removeEmpty(this.formData);
      console.log(reduced);
      url.search = qs.stringify(reduced, { skipNulls: true });
      return url;
    },
    init() {
      formDataOrig = JSON.stringify(this.formData);
    },
    changed() {
      this.$nextTick(() => {
        this.hasChanged = formDataOrig != JSON.stringify(this.formData);
      });
    }
  };
}
