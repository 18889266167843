export default function() {

  return {

    track(eventName = "regional_events", scrollIntoView = true) {
      let formData = new FormData(this.$el);

      let eventData = {
        "event": eventName,
        "regional_selection": formData.get('events-region')
      };

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(eventData);

      if(scrollIntoView) this.intoView();
    },

    intoView() {
      let eventsEl = document.getElementById('events');
      if((eventsEl.offsetTop - 100) < window.scrollY) {
        window.scrollTo({ top: eventsEl.offsetTop - 100, behavior: 'smooth' });
      }
    }
  };

}
