export default function(id, url, title) {
  return {

    yetToStart: true,
    video: null,
    complete: false,
    milestones: [25, 50, 75],

    init() {
      this.push(id);
    },

    push(id) {
      window._wq = window._wq || [];
      let self = this;
      _wq.push({ id: id, onReady: function(video) {
        console.log("I got a handle to the video!", video);
        self.video = video;
        self.addListeners();

        self.sendData({
          event: 'video_impression',
          video_title: title,
          video_provider: 'Wistia',
          video_url: url,
          video_play_type: 'In-page video',
          video_duration: self.video.duration(),
        }, 'impression');

        up.on('up:location:changed', (event) => {
          if(!self.yetToStart && !self.complete) {
            self.sendData({
              event: 'video_abandon',
              video_title: title,
              video_provider: 'Wistia',
              video_url: url,
              video_play_type: 'In-page video',
              video_duration: self.video.duration(),
              video_percent: self.percentageWatched(),
              video_current_time: self.video.time(),
              video_watch_time: self.video.time()
            }, 'abandon');
          }
        });
      }});
    },

    addListeners: function () {
      if (!this.video) return false;

      // Play / Resume
      this.video.bind("play", () => {
        let name = 'play';
        let data = {
          event: 'video_start',
          video_title: title,
          video_provider: 'Wistia',
          video_url: url,
          video_play_type: 'In-page video',
          video_duration: this.video.duration(),
          video_percent: 0,
          video_current_time: 0
        };
        if (!this.yetToStart) {
          data.event = 'video_resume';
          data.video_percent = this.percentageWatched();
          data.video_current_time = this.video.time();
          name = 'resume';
        }
        this.sendData(data, name);
        this.yetToStart = false;
      });

      // Pause
      this.video.bind("pause", () => {
        let data = {
          event: 'video_pause',
          video_title: title,
          video_provider: 'Wistia',
          video_url: url,
          video_play_type: 'In-page video',
          video_duration: this.video.duration(),
          video_percent: this.percentageWatched(),
          video_current_time: this.video.time()
        };
        this.sendData(data, 'pause');
      });

      // Complete
      this.video.bind("end", () => {
        this.complete = true;
        let data = {
          event: 'video_complete',
          video_title: title,
          video_provider: 'Wistia',
          video_url: url,
          video_play_type: 'In-page video',
          video_duration: this.video.duration(),
          video_percent: 1,
          video_current_time: this.video.time(),
          video_watch_time: this.video.duration()
        };
        this.sendData(data, 'complete');
      });

      this.milestones.forEach(m => {
        console.log(m);
        let secs = Math.round(this.video.duration() * (m/100));
        this.video.bind('secondchange', (s) => {
          if(s === secs) {
            let data = {
              event: 'video_progress',
              video_title: title,
              video_provider: 'Wistia',
              video_url: url,
              video_play_type: 'In-page video',
              video_duration: this.video.duration(),
              video_current_time: this.video.time(),
              video_percent: m
            };
            this.sendData(data, 'progress');
          }
        });
      });
    },

    percentageWatched() {
      let num = this.video.time() / this.video.duration();
      return Math.round(num*100);
    },

    sendData(data, name) {
      console.log(`Tracking event - Video ${name}`, data);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    },

    play() {
      this.video.play();
    }
  };
}
