import download from "downloadjs";

export default function(options) {

  if(!options.url || !options.title) return;

  const defaults = {
    downloadText: "Download PDF",
    downloadingText: "Downloading...",
    downloadOnLoad: false,
  };

  options = Object.assign(defaults, options);

  return {
    _downloading: false,
    buttonText: options.downloadText,

    init() {
      if(options.downloadOnLoad && up.layer.stack.length > 1) this.downloadFile();
    },

    onMessage($event) {
      if($event.data.type !== undefined && typeof $event.data.type === 'string' && $event.data.type == 'zendesk-form:values') {
        if(!options.downloadOnLoad && up.layer.stack.length > 1) {
          this.downloadFile();
        }
      }
    },

    forceDownload() {
      this.downloading = true;
      this.downloadFile();
    },

    set downloading(val) {
      this._downloading = val;
      this.buttonText = val ? options.downloadingText : options.downloadText;
    },

    get downloading() {
      return this._downloading;
    },

    downloadFile() {
      // Download from URL
      let self = this;
      let x = new XMLHttpRequest();
      x.open( "GET", options.url , true);
      x.responseType="blob";
      x.onload= function(e){
        let mainTitle = "CX Trends 2024";
        let pdfTitle = options.title === mainTitle ? mainTitle : `${options.title} - ${mainTitle}`;
        download(e.target.response, `${pdfTitle}.pdf`, "application/pdf");
        self.downloadStatus = null;
        self.downloading = false;
        self.$dispatch('download-complete');
      };
      x.send();
    }
  };

}
