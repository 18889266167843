export default function(total) {

  const duration = 6000;
  let playTimeout = null;
  let resizeTimeout = null;

  return {
    autoplay: true,
    wrapperWidth: null,
    wrapperHeight: null,
    currentIndex: 1,
    playing: false,
    resizing: false,

    init() {
      this.setup();
    },

    setup() {
      this.wrapperWidth = this.$refs.wrapper.offsetWidth;
      this.wrapperHeight = this.$refs.wrapper.offsetHeight;
      this.$refs.wrapper.style.width = `${this.wrapperWidth}px`;
      this.$refs.wrapper.style.height = `${this.wrapperHeight}px`;
    },

    resize() {
      clearTimeout(resizeTimeout);
      this.resizing = true;
      this.$refs.wrapper.style = { width: null, height: null };

      resizeTimeout = setTimeout(() => {
        this.setup();
        this.resizing = false;
      }, 500);
    },

    to(index = null, stop = true) {
      clearTimeout(playTimeout);
      if(stop) this.autoplay = false;

      if(index) {
        this.currentIndex = index;
      }
      else {
        this.currentIndex = this.currentIndex === total ? 1 : this.currentIndex + 1;
      }

      if(this.autoplay) this.play();
    },

    play() {
      this.playing = true;

      playTimeout = setTimeout(() => {
        this.playing = false;
        this.to(null, false);
      }, duration);
    },

    pause() {
      this.playing = false;
      clearTimeout(playTimeout);
    }
  };
}
