import axios from 'axios';

export default (redirect = null, close = false, autoSubmit = false, noTracking = false, site = null, modalClass = 'report') => ({
  formData: null,
  submitting: false,
  redirect: redirect,
  close: close,
  submit() {
    if (this.submitting) return;
    this.submitting = true;
    this.$el.style.pointerEvents = 'none';
    this.formData.dataCapture = true;
    this.formData.site = site;
    console.log(this.formData);

    axios.get('/actions/sitemodule/preferences', {
      params: this.formData
    })
      .then(() => {

        if(close) {
          up.layer.accept();
          up.reload();
          return;
        }

        if(!redirect) return;

        if(up.layer.stack.length === 1 && redirect) {
          document.location.href = redirect;
          return;
        }

        let modal = document.querySelector('up-modal');
        modal.classList.remove('data-capture');
        up.reload({ url: redirect, transition: 'cross-fade', scroll: 'layer', navigate: true })
          .then(() => {
            modal.classList.add(modalClass);
          });
      })
      .catch(err => {
        console.log(err);
      });
  },
  onMessage($event) {
    console.log($event.data);
    window.dataLayer = window.dataLayer || [];

    if(typeof $event.data === 'string' && $event.data.startsWith('zendesk-form:')) {
      // zendesk-form:ready
      // zendesk-form:pending
      // zendesk-form:success
      // zendesk-form:failed
      window.dataLayer.push({
        'event' : $event.data
      });
    }
    console.log($event.data.type, typeof $event.data.type);

    if($event.data.type !== undefined && typeof $event.data.type === 'string') {

      console.log(`Zendesk form event: ${$event.data.type}`);

      if($event.data.type === 'zendesk-form:dimensions') {
        this.$el.style.height = $event.data.data.height + 'px';
      }

      if($event.data.type === 'zendesk-form:values') {
        if(!noTracking) {
          let eventData = {
            'event': 'form_submission_complete',
            'number_of_employees': $event.data.data.size
          };
          console.log('Tracking event - Form submission', eventData);
          window.dataLayer.push(eventData);
        }
        this.formData = $event.data.data;
        this.submit();
      }

      if($event.data.type === 'zendesk-form:failure') {
        let eventData = {
          'event': 'form_submission_failure',
          'failure_reason': undefined
        };
        console.log('Tracking event - Form failure', eventData);
        window.dataLayer.push(eventData);
      }
    }
  },
  redirectUpdate(e) {
    if(!e.detail.length) {
      let orig = this.$el.dataset.origRedirect;
      console.log(orig);
      this.redirect = orig;
      this.$el.src = this.$el.src.replace(/\?redirect=(.*)\&/, `?redirect=${orig}&`);
    }
    else {
      let url = `${window.location.origin}${e.detail}`;
      this.redirect = url;
      this.$el.src = this.$el.src.replace(/\?redirect=(.*)\&/, `?redirect=${url}&`);
    }
  },
  init() {
    this.$el.setAttribute('scrolling', 'no');
    this.$el.style.height = '0px';
  }
});
