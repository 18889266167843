/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";
import "@js/parts/loadjs";
import "@js/parts/unpoly";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import resize from '@aerni/alpine-resize';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
import collapse from '@alpinejs/collapse';

import app from "@js/parts/alpine/app";
import dataCapture from "@js/parts/alpine/dataCapture";
import reportLink from "@js/parts/alpine/reportLink";
import downloadPdf from "@js/parts/alpine/downloadPdf";
import header from "@js/parts/alpine/header";
import stats from "@js/parts/alpine/stats";
import carousel from "@js/parts/alpine/carousel";
import eventLink from "@js/parts/alpine/eventLink";
import regionSelector from "@js/parts/alpine/regionSelector";
import linkButton from "@js/parts/alpine/linkButton";
import wistia from "@js/parts/alpine/wistia";
import { salesUrlShare } from '@js/parts/alpine/salesUrlShare';

Alpine.plugin(resize);
Alpine.plugin(intersect);
Alpine.plugin(persist);
Alpine.plugin(collapse);

/* data components */
Alpine.data('app', app);
Alpine.data('reportLink', reportLink);
Alpine.data('downloadPdf', downloadPdf);
Alpine.data('header', header);
Alpine.data('stats', stats);
Alpine.data('carousel', carousel);
Alpine.data('dataCapture', dataCapture);
Alpine.data('salesUrlShare', salesUrlShare);
Alpine.data('eventLink', eventLink);
Alpine.data('regionSelector', regionSelector);
Alpine.data('linkButton', linkButton);
Alpine.data('wistia', wistia);

/* start your engines */
window.Alpine = Alpine;
Alpine.start();
