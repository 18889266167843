export default function() {
  return {
    skip: 1,
    atBeginning: false,
    atEnd: false,
    currentIndex: 0,
    slidesVisible: [],
    allVisible: null,
    next() {
      this.to((current, offset) => {
        console.log(current, (offset * this.skip));
        return current + (offset * this.skip)
      });
    },
    prev() {
      this.to((current, offset) => current - (offset * this.skip))
    },
    goTo(index) {
      this.to((current, offset) => {
        console.log(current, (offset * index));
        return offset * index;
      });
    },
    to(strategy) {
      let slider = this.$refs.slider
      let current = slider.scrollLeft
      let offset = slider.firstElementChild.getBoundingClientRect().width
      slider.scrollTo({left: strategy(current, offset), behavior: 'smooth'})
    },
    focusableWhenVisible: {
      'x-intersect:enter'() {
        this.$el.removeAttribute('tabindex')
      },
      'x-intersect:leave'() {
        this.$el.setAttribute('tabindex', '-1')
      },
    },
    disableNextAndPreviousButtons: {
      'x-intersect:enter.margin.-150px'() {
        let slideEls = this.$el.parentElement.getElementsByClassName('slide');

        // If this is the first slide.
        if (slideEls[0] === this.$el) {
          this.atBeginning = true
          // If this is the last slide.
        } else if (slideEls[slideEls.length - 1] === this.$el) {
          this.atEnd = true
        }

        this.slidesVisible.push(this.$el);
        this.allVisible = this.slidesVisble == slideEls.length;
        this.currentIndex = Array.from(slideEls).indexOf(this.$el);
      },
      'x-intersect:leave.margin.-100px'() {
        let slideEls = this.$el.parentElement.getElementsByClassName('slide');

        // If this is the first slide.
        if (slideEls[0] === this.$el) {
          this.atBeginning = false
          // If this is the last slide.
        } else if (slideEls[slideEls.length - 1] === this.$el) {
          this.atEnd = false
        }

        this.slidesVisible.pop(this.$el);
        this.allVisible = false;
      },
    },
  };
}
