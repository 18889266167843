export default function(url, open, canDownload = false) {

  return {
    init() {
      if(open) {
        up.navigate({ url: url, layer: 'new', mode: 'modal', history: false });
        const currentUrl = new URL(window.location.href);
        const params = new URLSearchParams(currentUrl.search);
        params.delete("report");
        this.$root.remove();
        history.replaceState(null, '', `${window.location.pathname}?${params}`);
      }
    },

    track(text) {
      console.log(`Tracking event - ${text} (${canDownload ? 'gated' : 'ungated'})`);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': canDownload ? 'gated_download' : 'ungated_download',
        'download_button_name': text
      });
    }
  };

}
